<template>
    <header class="relative flex flex-col items-center gap-y-4 mt-2 md:mt-4">
        <div class="mt-8 md:mt-0"></div>
        <RouterLink :to="{ name: 'dashboard' }" class="mt-4 md:mt-0">
            <img src="../../../images/logo.svg"
                 width="200"
                 height="84"
                 alt="Functional Synergy"
                 loading="lazy"
                 decoding="async">
        </RouterLink>

        <div class="text-center w-full mt-4 py-6 bg-fs-purple-500">
            <ul class="header_nav-nav">
                <template v-if="loggedIn">
                    <li><RouterLink :to="{ name: 'dashboard' }">Dashboard</RouterLink></li>
                </template>
                <li v-else><a href="/login">Sign In</a></li>
                <li><a href="https://www.functionalsynergy.com/contact/">Support</a></li>
                <li><a href="https://www.functionalsynergy.com/">About</a></li>
            </ul>
        </div>

        <nav v-if="loggedIn" class="header-logout">
            <RouterLink :to="{ name: 'user-profile-edit' }" class="flex justify-center lg:justify-start items-center">
                <PublicIcon icon="user" width="12" height="12" class="mr-1 text-fs-gray-500" />
                {{ rootStore.user.name }}
            </RouterLink>
            <a v-if="rootStore.hasRole('ROLE_ADMIN')" href="/admin">Admin</a>
            <a href="/logout">Sign Out</a>
        </nav>
    </header>
    <CourseHeader />
    <div class="flex overflow-hidden">
        <main class="w-full">
            <!-- *** where the router component is placed *** -->
            <RouterView :key="$route.path" />
        </main>
        <FsSidebar />
    </div>

    <footer class="footer">
        <div class="footer-content">
            <div class="flex items-center text-white fill-current">
                <a href="https://www.functionalsynergy.com/">
                    <img src="../../../images/logo-icon-footer.svg"
                         width="96"
                         height="96"
                         alt="Functional Synergy">
                </a>
                <div class="ml-6">
                    <span class="text-lg">Functional Synergy</span>
                    <div class="flex items-center mt-4">
                        <a href="https://www.facebook.com/susi.hately.FS/" class="footer-social -ml-1 ">
                            <PublicIcon icon="social-facebook" width="20" height="20" />
                        </a>
                        <a href="https://www.instagram.com/susi.hately/" class="footer-social">
                            <PublicIcon icon="social-instagram" width="20" height="20" />
                        </a>
                        <a href="https://www.linkedin.com/company/functionalsynergy/" class="footer-social">
                            <PublicIcon icon="social-linkedin" width="18" height="18" />
                        </a>
                        <a href="https://www.youtube.com/@FromPaintoPossibility" class="footer-social">
                            <PublicIcon icon="social-youtube" width="25" height="20" />
                        </a>
                    </div>
                </div>
            </div>
            <div class="mt-8 md:mt-5 text-center md:text-right">
                <div class="mb-8 text-white">
                    © {{ new Date().getFullYear() }} Functional Synergy All Rights Reserved
                </div>
                <a href="https://functionalsynergy.com/contact/" class="footer-link inline-block">
                    Contact Us
                </a>
            </div>
        </div>
    </footer>
</template>

<script setup>
import { useHead } from '@unhead/vue';
import { computed } from 'vue';
import { useRootStore } from './stores/root';
import FsSidebar from '@/user/menu/sidebar.vue';
import CourseHeader from '@/user/course/component/header.vue';

const rootStore = useRootStore();

useHead({
    title: 'Functional Synergy Classroom',
    titleTemplate: '%s | Functional Synergy Classroom',
});

const loggedIn = computed(() => rootStore.loggedIn);
</script>

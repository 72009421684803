import { createRouter, createWebHistory } from 'vue-router';
import { scrollBehavior, parseQuery, stringifyQuery, logPageView, beforeEach } from '@/common/router_helpers';
import { useRootStore } from '@/user/stores/root';
import { useSidebarStore } from '@/user/stores/sidebar';

const router = createRouter({
    history: createWebHistory(),

    routes: [
        // *********************************************************************
        // PUBLIC
        // *********************************************************************
        {
            name: 'login',
            path: '/login',
            component: () => import('./login'),
        },
        {
            path: '/recover',
            component: () => import('./user_recover'),
            children: [
                { path: '', redirect: '/recover/initiate' },
                {
                    name: 'user-recover-initiate',
                    path: 'initiate',
                    component: () => import('./user_recover/initiate'),
                },
                {
                    name: 'user-recover-reset',
                    path: 'reset',
                    component: () => import('./user_recover/reset'),
                },
            ],
        },
        {
            name: 'user-activate',
            path: '/activate',
            component: () => import('./user_activate'),
        },

        // *********************************************************************
        // LOGGED IN
        // *********************************************************************
        {
            path: '/dashboard',
            name: 'dashboard',
            component: () => import('./dashboard'),
            meta: {
                requiresAuth: true,
                role: 'ROLE_USER',
            },
        },
        {
            path: '/course/view/:userCourseId',
            component: () => import('./course'),
            children: [
                {
                    name: 'course-start',
                    path: 'start',
                    component: () => import('./course/start'),
                    props: true,
                },
                {
                    name: 'course-view',
                    path: ':moduleId',
                    component: () => import('./course/view'),
                    props: true,
                },
                {
                    name: 'course-quiz',
                    path: ':moduleId/quiz',
                    component: () => import('./course/quiz'),
                    props: true,
                },
            ],
            meta: {
                requiresAuth: true,
                role: 'ROLE_USER',
            },
        },
        // admin only preview course route
        {
            path: '/course/preview/:courseId',
            component: () => import('./course'),
            children: [
                {
                    name: 'course-preview',
                    path: ':moduleId',
                    component: () => import('./course/preview'),
                    props: true,
                },
            ],
            meta: {
                requiresAuth: true,
                role: 'ROLE_ADMIN',
            },
        },
        {
            path: '/profile',
            component: () => import('./profile_edit'),
            redirect: '/profile/edit',
            children: [
                {
                    name: 'user-profile-edit',
                    path: 'edit',
                    component: () => import('./profile_edit/profile'),
                },
                {
                    name: 'user-profile-edit-password',
                    path: 'password',
                    component: () => import('./profile_edit/password'),
                },
            ],
            meta: {
                requiresAuth: true,
                role: 'ROLE_USER',
            },
        },

        // *********************************************************************
        // ERRORS
        // *********************************************************************
        {
            path: '/:pathMatch(.*)*',
            name: '404',
            component: () => import('./error/404'),
        },
        {
            path: '/:pathMatch(.*)*',
            name: '403',
            component: () => import('./error/403'),
        },
    ],

    scrollBehavior,
    parseQuery,
    stringifyQuery,
});

router.beforeEach(beforeEach(router.resolve({ name: 'login' }).href, useRootStore, 'user'));

router.afterEach((to) => {
    const sidebarStore = useSidebarStore();
    if ([ 'course-view', 'course-quiz' ].includes(to.name)) {
        sidebarStore.setUserCourseId(to.params.userCourseId);
        sidebarStore.setCourseId(null);
    } else if ([ 'course-preview' ].includes(to.name)) {
        sidebarStore.setCourseId(to.params.courseId);
        sidebarStore.setUserCourseId(null);
    } else {
        sidebarStore.unsetUserCourseId();
        sidebarStore.unsetCourseId();
    }

    logPageView(to);
});

export default router;

import { defineStore } from 'pinia';

export const useSidebarStore = defineStore('sidebarStore', {
    state: () => ({
        course: [],
        // student is viewing a course
        currentUserCourseId: null,
        // admin is previewing a course
        currentCourseId: null,
        userCourseModules: [],
    }),

    getters: {
        viewedModuleIds: (state) => {
            if (state.userCourseModules.length === 0) {
                return [];
            }

            return state.userCourseModules.filter((m) => m.firstViewed).map((m) => m.courseModule.moduleId);
        },
        completedModuleIds: (state) => {
            if (state.userCourseModules.length === 0) {
                return [];
            }

            return state.userCourseModules.filter((m) => m.completed).map((m) => m.courseModule.moduleId);
        },

        sectionStatus () {
            return (sectionId) => {
                const sectionModules = this.course.modules.filter((m) => m.sectionId === sectionId);
                const userSectionModules = this.userCourseModules.filter((m) => m.courseModule.sectionId === sectionId);
                const completed = userSectionModules.filter((m) => m.completed);

                if (completed.length === sectionModules.length) {
                    return 'completed';
                }

                if (userSectionModules.filter((m) => m.firstViewed).length > 0) {
                    return 'viewed';
                }

                return null;
            };
        },
        isModuleViewed () {
            return (moduleId) => this.viewedModuleIds.includes(moduleId);
        },
        isModuleCompleted () {
            return (moduleId) => this.completedModuleIds.includes(moduleId);
        },
    },

    actions: {
        setUserCourseId (userCourseId) {
            this.currentUserCourseId = userCourseId;
        },
        unsetUserCourseId () {
            this.currentUserCourseId = null;
        },
        setCourseId (courseId) {
            this.currentCourseId = courseId;
        },
        unsetCourseId () {
            this.currentCourseId = null;
        },
        setCourse (course) {
            this.course = course;
        },
        setUserCourseModules (modules) {
            this.userCourseModules = modules;
        },
        unsetUserCourseModules () {
            this.userCourseModules = [];
        },
        viewedModule (sectionId, moduleId) {
            const userCourseModule = this.userCourseModules.find(
                (m) => m.courseModule.sectionId === sectionId && m.courseModule.moduleId === moduleId
            );

            if (userCourseModule) {
                userCourseModule.firstViewed = new Date();
            } else {
                this.userCourseModules.push({
                    courseModule: {
                        sectionId,
                        moduleId,
                    },
                    firstViewed: new Date(),
                });
            }
        },
        completeModule (sectionId, moduleId) {
            const userCourseModule = this.userCourseModules.find(
                (m) => m.courseModule.sectionId === sectionId && m.courseModule.moduleId === moduleId
            );

            if (userCourseModule) {
                userCourseModule.completed = new Date();
            } else {
                // this should never happen, but just in case
                this.userCourseModules.push({
                    courseModule: {
                        sectionId,
                        moduleId,
                    },
                    firstViewed: new Date(),
                    completed: new Date(),
                });
            }
        },
    },
});

<template>
    <div v-if="show" ref="courseHeader" class="w-full border-b">
        <div class="max-w-[100rem] mx-auto">
            <div class="hidden lg:flex justify-between items-center mx-auto px-8 xl:px-4">
                <div class="py-12 pl-6 xl:pl-0">
                    <h5 class="mt-0 mb-2 text-[15px] font-semibold text-fs-gray-300 tracking-wide uppercase">Module</h5>
                    <h1 class="text-3xl mt-0 font-semibold text-fs-gray-900">{{ currentModuleTitle }}</h1>
                </div>
                <div class="w-64">
                    <h1 class="mt-0 mb-0 text-base">{{ course.courseName }}</h1>
                    <CourseProgress v-if="!isPreview" :user-course="userCourse" />
                </div>
            </div>

            <button v-if="course.modules.length > 1"
                    type="button"
                    class="course_header-menu-toggle"
                    :class="{ 'rotate-0': visible }"
                    :title="visible ? 'Hide Sidebar' : 'Show Sidebar'"
                    @click="visible = !visible">
                <span class="mr-2">Sections</span>
                <PublicIcon icon="arrow-section"
                            class="-rotate-90"
                            :class="{ 'rotate-0' : visible }"
                            width="10"
                            height="10" />
            </button>
            <ul class="section-list-header" :class="{ 'block lg:hidden': visible }">
                <ModuleSectionItem v-for="_section in course.sections"
                                   :key="_section.sectionId"
                                   :section="_section"
                                   :course="course"
                                   :open-sections="openSections"
                                   @toggle-section="toggleSection(_section.sectionId)"
                                   @link-click="visible = false" />
            </ul>

            <div class="lg:hidden py-8 px-4">
                <h5 class="mt-0 mb-2 text-[15px] font-semibold uppercase text-fs-gray-300 tracking-wide">Course</h5>
                <h1 class="my-0 text-xl font-normal text-fs-gray-900">{{ course.courseName }}</h1>
            </div>
        </div>
    </div>
</template>


<script setup>
import { computed, ref, watch } from 'vue';
import { logError } from '@/common/lib.js';
import { useSidebarStore } from '@/user/stores/sidebar.js';
import { useQuery } from '@vue/apollo-composable';
import router from '../../router';
import { HeaderQuery } from '@/user/queries/user_course.query.graphql';
import { HeaderPreviewQuery } from '@/user/queries/course.query.graphql';
import ModuleSectionItem from '@/common/public_section_list_item.vue';
import { useRootStore } from '@/user/stores/root.js';
import CourseProgress from '@/user/course/component/course_progress.vue';

const rootStore = useRootStore();
const sidebarStore = useSidebarStore();

// true when show
const visible = ref(false);
const openSections = ref(new Set());
const userCourse = ref(null);
const course = ref(null);

// true when have a course & on a course page
const show = computed(() => {
    if (!course.value) {
        return false;
    }

    return [ 'course-view', 'course-preview', 'course-quiz' ].includes(router.currentRoute.value.name);
});
const currentModuleId = computed(() => {
    return router.currentRoute.value.params?.moduleId;
});
const isPreview = computed(() => {
    return 'course-preview' === router.currentRoute.value.name;
});

// for the user when viewing the course
watch(() => sidebarStore.currentUserCourseId, async (userCourseId) => {
    if (userCourseId) {
        loadCourse(userCourseId);
    }
});
// for previewing courses
watch(() => sidebarStore.currentCourseId, (courseId) => {
    if (courseId) {
        try {
            loadCourseForAdmin(courseId);
        } catch (e) {
            logError(e);
            course.value = null;
        }
    }
});
sidebarStore.$onAction(({ name }) => {
    if ('completeModule' === name) {
        loadCourse(sidebarStore.currentUserCourseId);
    }
});

const toggleSection = (sectionId) => {
    if (openSections.value.has(sectionId)) {
        openSections.value.delete(sectionId);
    } else {
        openSections.value.add(sectionId);
    }
};
const openCurrentSection = () => {
    if (course.value) {
        const currentSection = course.value.modules.find((m) => m.moduleId === currentModuleId.value);
        if (currentSection) {
            openSections.value.add(currentSection.sectionId);
        }
    }
};

const currentModuleTitle = computed(() => {
    if (!currentModuleId.value) {
        return null;
    }

    const module = course.value.modules.find((module) => module.moduleId === currentModuleId.value);

    return module ? module.moduleTitle : null;
});

const loadCourse = (userCourseId) => {
    try {
        const { onResult, onError } = useQuery(HeaderQuery, {
            userCourseId,
        });

        onResult(({ data: { UserCourse } }) => {
            userCourse.value = UserCourse;
            course.value = UserCourse.course;
            openCurrentSection();

            // loads extra data that's only available to admins
            if (rootStore.hasRole('ROLE_ADMIN')) {
                try {
                    loadCourseForAdmin(course.value.courseId);
                } catch (e) {
                    logError(e);
                }
            }
        });
        onError((e) => {
            logError(e);
            userCourse.value = null;
            course.value = null;
        });

    } catch (e) {
        logError(e);
        userCourse.value = null;
        course.value = null;
    }
}
const loadCourseForAdmin = (courseId) => {
    const { onResult, onError } = useQuery(HeaderPreviewQuery, {
        courseId,
    });

    onResult(({ data: { CourseById } }) => {
        course.value = CourseById;
    });
    onError((e) => {
        logError(e);
        course.value = null;
    });
};
</script>

<template>
    <div class="flex justify-between items-end py-2 text-sm text-gray-500">
        <template v-if="'COMPLETED' === courseStatus">
            <span>Completed</span>
            <a v-if="userCourse.certificateUrl"
               :href="userCourse.certificateUrl"
               class="text-right">Download Certificate</a>
        </template>
        <template v-else-if="'IN_PROGRESS' === courseStatus">
            {{ userCourse.percentageComplete + '%' }} complete
        </template>
        <template v-else-if="'IN_QUIZ' === courseStatus">Take the Quiz</template>
        <template v-else>Start</template>
    </div>

    <div class="bg-gray-200 h-4 rounded-lg">
        <div class="bg-fs-dark h-4 rounded-lg"
             :class="{ 'bg-fs-green-100' : 'COMPLETED' === courseStatus }"
             :style="{ width: userCourse.percentageComplete + '%' }" />
    </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    userCourse: {
        type: Object,
        required: true,
    },
});

const courseStatus = computed(() => {
    if (!props.userCourse.started) {
        return 'NOT_STARTED';
    } else if (props.userCourse.completed) {
        return 'COMPLETED';
    } else if (props.userCourse.inQuiz) {
        return 'IN_QUIZ';
    } else {
        return 'IN_PROGRESS';
    }
});
</script>

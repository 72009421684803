<template>
    <li v-if="section.modules.length > 0" class="text-sm border-b last:border-none border-fs-gray-250">
        <template v-if="course.sections.length > 1">
            <button class="section_list-section_title" :title="sectionTitleAttr" @click="$emit('toggle-section')">
                <span class="flex items-center lg:max-w-[12rem] 2xl:max-w-[18rem]">
                    <span class="mr-2 -rotate-90" :class="{ 'rotate-0': openSections.has(section.sectionId) }">
                        <PublicIcon icon="arrow-section" width="10" height="10" />
                    </span>
                    <span>{{ section.sectionName }}</span>
                </span>
                <PublicIcon :icon="sectionIcon"
                            width="45"
                            height="45"
                            class="section-list-icon"
                            :class="sectionIconClasses" />
            </button>
        </template>
        <ul class="section-list" :class="{ 'block': openSections.has(section.sectionId) }">
            <li v-for="_module in section.modules" :key="_module.moduleId" class="mb-2">
                <RouterLink :to="courseViewRoute(_module)"
                            class="course_view-module"
                            :class="moduleLinkClasses(_module)"
                            :title="moduleTitleAttr(_module.moduleId)"
                            @click="moduleClick(_module)">
                    <div class="lg:max-w-[10rem] 2xl:max-w-[15rem]">
                        <h5 class="sidebar_module-heading">Module</h5>
                        {{ _module.moduleTitle }}
                        <div v-if="rootStore.hasRole('ROLE_ADMIN') && !_module.published" class="text-xs italic">
                            Unpublished
                        </div>
                    </div>

                    <PublicIcon :icon="moduleIcon(_module.moduleId)"
                                width="45"
                                height="45"
                                class="section-list-icon"
                                :class="moduleIconClasses(_module.moduleId)" />
                </RouterLink>
            </li>
        </ul>
    </li>
</template>

<script setup>
import { useSidebarStore } from '@/user/stores/sidebar.js';
import { computed, ref, watch } from 'vue';
import router from '@/user/router.js';
import { useRootStore } from '@/user/stores/root.js';

const rootStore = useRootStore();
const sidebarStore = useSidebarStore();

const emit = defineEmits([ 'toggle-section', 'link-click' ]);

const props = defineProps({
    section: {
        type: Object,
        required: true,
    },
    course: {
        type: Object,
        required: true,
    },
    openSections: {
        type: Set,
        required: true,
    },
});

const currentModuleId = computed(() => {
    return router.currentRoute.value.params?.moduleId;
});

const isPreview = computed(() => {
    return 'course-preview' === router.currentRoute.value.name;
});

const sectionStatus = ref();
watch(sidebarStore.userCourseModules, () => {
    sectionStatus.value = sidebarStore.sectionStatus(props.section.sectionId);
});

const courseViewRoute = (module) => {
    if (isPreview.value || (rootStore.hasRole('ROLE_ADMIN') && !module.published)) {
        return {
            name: 'course-preview',
            params: {
                courseId: sidebarStore.currentCourseId ?? sidebarStore.currentUserCourseId,
                moduleId: module.moduleId,
            },
        };
    }

    return {
        name: 'course-view',
        params: {
            userCourseId: sidebarStore.currentUserCourseId,
            moduleId: module.moduleId,
        },
    };
};

const moduleClick = (module) => {
    if (rootStore.hasRole('ROLE_ADMIN') && !isPreview.value && !module.published) {
        alert('You are being taken to a preview of this module because it is not published.');
    }

    emit('link-click');
};

const isCurrentModule = (moduleId) => {
    return moduleId === currentModuleId.value;
};

const sectionIcon = computed(() => {
    if ('completed' === sectionStatus.value) {
        return 'checkmark';
    } else if ('viewed' === sectionStatus.value) {
        return 'clock';
    } else {
        return 'empty-circle';
    }
});
const sectionIconClasses = computed(() => {
    if ('completed' === sectionStatus.value) {
        return 'text-fs-green-100';
    } else if ('viewed' === sectionStatus.value) {
        return 'text-fs-dark';
    } else {
        return 'text-fs-gray-500';
    }
});
const sectionTitleAttr = computed(() => {
    if ('completed' === sectionStatus.value) {
        return 'Completed';
    } else if ('viewed' === sectionStatus.value) {
        return 'Partially complete';
    } else {
        return 'Incomplete';
    }
});

const moduleLinkClasses = (module) => {
    return {
        'course_view-module-current': isCurrentModule(module.moduleId),
        'course_view-module-unpublished': rootStore.hasRole('ROLE_ADMIN') && !module.published,
    };
};
const moduleIcon = (moduleId) => {
    if (sidebarStore.isModuleCompleted(moduleId)) {
        return 'checkmark';
    } else if (sidebarStore.isModuleViewed(moduleId) || isCurrentModule(moduleId)) {
        return isCurrentModule(moduleId) ? 'clock' : 'clock-filled';
    } else {
        return 'empty-circle';
    }
};
const moduleIconClasses = (moduleId) => {
    if (sidebarStore.isModuleCompleted(moduleId)) {
        return isCurrentModule(moduleId) ? 'text-white' : 'text-fs-green-100';
    } else if (sidebarStore.isModuleViewed(moduleId) || isCurrentModule(moduleId)) {
        return isCurrentModule(moduleId) ? 'text-white' : 'text-fs-dark';
    } else {
        return 'text-fs-gray-500';
    }
};
const moduleTitleAttr = (moduleId) => {
    if (sidebarStore.isModuleCompleted(moduleId)) {
        return 'Complete';
    } else if (sidebarStore.isModuleViewed(moduleId)) {
        return 'Viewed';
    } else {
        return 'New Module';
    }
};
</script>

<template>
    <div v-if="showModules" class="hidden lg:block w-0 lg:w-80 2xl:w-[26rem] lg:ml-8 xl:mr-0">
        <div class="course-sidebar">
            <ul class="list-none pl-0 pt-2">
                <ModuleSectionItem v-for="_section in course.sections"
                                   :key="_section.sectionId"
                                   :section="_section"
                                   :course="course"
                                   :open-sections="openSections"
                                   @toggle-section="toggleSection(_section.sectionId)"
                                   @link-click="openAndCloseOthers(_section.sectionId)" />
            </ul>
        </div>
    </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { logError } from '@/common/lib.js';
import { useRootStore } from '@/user/stores/root.js';
import { useSidebarStore } from '@/user/stores/sidebar.js';
import { useQuery } from '@vue/apollo-composable';
import router from '../router';
import { SidebarQuery } from '@/user/queries/user_course.query.graphql';
import { SidebarPreviewQuery } from '@/user/queries/course.query.graphql';
import ModuleSectionItem from '@/common/public_section_list_item.vue';

const rootStore = useRootStore();
const sidebarStore = useSidebarStore();

const currentModuleId = ref(null);
const openSections = ref(new Set());
const userCourse = ref(null);
const course = ref(null);

// show the list of modules when not in a quiz, but in a course view
const showModules = computed(() => {
    if (!course.value) {
        return false;
    }

    if (![ 'course-view', 'course-preview' ].includes(router.currentRoute.value.name)) {
        return false;
    }

    return course.value?.modules.length > 1;
});

// for the user when viewing the course
watch(() => sidebarStore.currentUserCourseId, async (userCourseId) => {
    if (userCourseId) {
        sidebarStore.unsetUserCourseModules();

        try {
            const { onResult, onError } = useQuery(SidebarQuery, {
                userCourseId,
            });

            onResult(({ data: { UserCourse } }) => {
                userCourse.value = UserCourse;
                course.value = UserCourse.course;
                sidebarStore.setCourse(course.value);
                sidebarStore.setUserCourseModules(userCourse.value.userCourseModules.filter((m) => m.courseModule));
                openCurrentSection();

                if (rootStore.hasRole('ROLE_ADMIN')) {
                    try {
                        loadCourseForAdmin(course.value.courseId);
                    } catch (e) {
                        logError(e);
                    }
                }
            });
            onError((e) => {
                logError(e);
                userCourse.value = null;
                course.value = null;
            });

        } catch (e) {
            logError(e);
            userCourse.value = null;
            course.value = null;
        }
    } else if (!sidebarStore.currentCourseId) {
        // if the preview value is unset, reset the sidebar so the sidebar doesn't flash on a different course
        reset();
    }
});
// for previewing courses
watch(() => sidebarStore.currentCourseId, (courseId) => {
    if (courseId) {
        sidebarStore.unsetUserCourseModules();

        try {
            loadCourseForAdmin(courseId);
            openCurrentSection();
        } catch (e) {
            logError(e);
            course.value = null;
        }
    } else if (!sidebarStore.currentUserCourseId) {
        // see above
        reset();
    }
});

watch(() => router.currentRoute.value.params?.moduleId, (moduleId) => {
    if (moduleId) {
        currentModuleId.value = moduleId;
        openCurrentSection();
    } else {
        currentModuleId.value = null;
    }
});

const toggleSection = (sectionId) => {
    if (openSections.value.has(sectionId)) {
        openSections.value.delete(sectionId);
    } else {
        openSections.value.add(sectionId);
    }
};
const openAndCloseOthers = (sectionId) => {
    openSections.value = new Set(sectionId);
};
const openCurrentSection = () => {
    if (course.value) {
        const currentModule = course.value.modules.find((m) => m.moduleId === currentModuleId.value);
        if (currentModule) {
            openSections.value.add(currentModule.sectionId);
        }
    }
};

const loadCourseForAdmin = async (courseId) => {
    const { onResult, onError } = useQuery(SidebarPreviewQuery, {
        courseId,
    });

    onResult(({ data: { CourseById } }) => {
        course.value = CourseById;
        openCurrentSection();
    });
    onError((e) => {
        logError(e);
        course.value = null;
    });
};

const reset = () => {
    userCourse.value = null;
    course.value = null;
    currentModuleId.value = null;
    openSections.value = new Set();
};
</script>
